import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Com el Clathrus ruber , també es desenvolupa a partir d’una mena d’ou, que es clivella i deixa sortir una mena de xarxa de color vermell a la part superior interna. Està buit, i sustentat per una espècie de columnes blanques damunt un peu curt. La part interna és verdosa, conté les espores i desprèn una olor molt desagradable que atreu a les mosques. Pot mesurar de 4-6 cm d’altura. Les espores són el·lipsoidals, llises, hialines, de 5-6 x 2-3 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      